<template>
    <el-dialog
        :visible.sync="showModel"
        :close-on-click-modal="false">
        <div> 
            <el-checkbox-group v-model="checkList" @change="layerChange">
                <el-checkbox label="gj">轨迹</el-checkbox>
                <el-checkbox label="gw">管网</el-checkbox>
                <el-checkbox label="pq">片区</el-checkbox>
                <el-checkbox label="dkd">打卡点</el-checkbox>
                <!-- <el-checkbox label="sb">设备</el-checkbox> -->
              </el-checkbox-group>
        </div>
      <div id="mapAdd" style="width: 100%;height: 500px"></div>
    </el-dialog>
</template>

<script>
import { queryFeatrue } from "../../Gis/apis/geo";
import { formatLocation } from "../../Gis/utils/geoTool";
// import {getTrackWorkPage} from "@/RequestPort/checkingIn";
export default {
  name: "workLocus",
  data(){
    return{
      showModel:false,
      // 轨迹
      polyline: null,
      // 终点
      markerZhong: null,
      // 起点
      markerqi: null,
      // 管网
      wms: null,
      // 片区
      polygonNet:null,
      // 打卡点
      labelsLayer:null,
      // 设备
      equipmentes:null,
      checkList:['gj','gw','pq','dkd','sb'],
      datarow:{}
    }
  },
  mounted() {
    
  },
  methods:{
    layerChange(val){ 
        if(val.indexOf('gj')!=-1){
           if(this.polyline){
            this.polyline.show()
            if(this.datarow.trackPath.length > 0) { 
            this.markerZhong.show()
            this.markerqi.show()
            }
           }
        }else{
           if(this.polyline){
            this.polyline.hide()
            if(this.datarow.trackPath.length > 0) { 
            this.markerZhong.hide()
            this.markerqi.hide()
            }
           }
        }

        if(val.indexOf('gw')!=-1){
           if(this.wms){
            this.wms.show()
           }
        }else{
           if(this.wms){
            this.wms.hide()
           }
        }

        if(val.indexOf('pq')!=-1){
           if(this.polygonNet){
            this.polygonNet.show()
           }
        }else{
           if(this.polygonNet){
            this.polygonNet.hide()
           }
        }
        if(val.indexOf('dkd')!=-1){
           if(this.labelsLayer){
            this.labelsLayer.show()
           }
        }else{
           if(this.labelsLayer){
            this.labelsLayer.hide()
           }
        }
        if(val.indexOf('sb')!=-1){
           if(this.equipment){
            this.equipment.show()
           }
        }else{
           if(this.equipment){
            this.equipment.hide()
           }
        }
    },
    open(row){
      this.checkList=['gj','gw','pq','dkd','sb']
      this.layerChange(this.checkList)
      let that = this
      
      this.showModel = true
      this.$nextTick(() => {
        // if(row.networkPath && row.networkPath.length>0){
        //   that.map = new AMap.Map("mapAdd", {
        //     resizeEnable: true,
        //     center:row.networkPath[0],
        //     zoom: 14
        //   });
        // }
        if (row.trackPath && row.trackPath.length > 0) {
          let obj = {
            resizeEnable: true,
            zoom: 14,
          };
          if (row.trackPath && row.trackPath[0] && row.trackPath[0][0]) {
            obj.center = row.trackPath[0][0][0];
          }
          that.map = new AMap.Map("mapAdd", obj);

          that.map.on("complete", function () {
            that.openC(row);
          });
        } else if (row.networkPath && row.networkPath.length > 0) {
          let obj = {
            resizeEnable: true,
            zoom: 14,
          };
          if (row.networkPath && row.networkPath[0]) {
            obj.center = row.networkPath[0];
          }
          that.map = new AMap.Map("mapAdd", obj);

          that.map.on("complete", function () {
            that.openC(row);
          });
        }
        // 巡检轨迹
      // let polyline = new AMap.Polyline({
      //   path: row.trackPath,
      //   isOutline: true,
      //   outlineColor: "#ffeeff",
      //   borderWeight: 3,
      //   strokeColor: "#3366FF",
      //   strokeOpacity: 1,
      //   strokeWeight: 6,
      //   // 折线样式还支持 'dashed'
      //   strokeStyle: "solid",
      //   // strokeStyle是dashed时有效
      //   strokeDasharray: [10, 5],
      //   lineJoin: "round",
      //   lineCap: "round",
      //   zIndex: 50,
      // });
      // that.map.add(polyline);
        // 线
      // if(row.trackPath && row.trackPath.length>0){
      //   for(let i=0;i<row.trackPath.length;i++){
      //     let polyline = new AMap.Polyline({
      //       path: row.trackPath[i],
      //       isOutline: true,
      //       outlineColor: "#ffeeff",
      //       borderWeight: 1,
      //       strokeColor: "#000000",
      //       strokeOpacity: 1,
      //       strokeWeight: 2,
      //       // 折线样式还支持 'dashed'
      //       strokeStyle: "solid",
      //       // strokeStyle是dashed时有效
      //       strokeDasharray: [10, 5],
      //       lineJoin: "round",
      //       lineCap: "round",
      //       zIndex: 2021,
      //     });
      //     that.map.add(polyline);
      //     // 起点
      //     // let qidian = require('@/assets/start.png')
      //     let qidian = new AMap.Icon({
      //       size: new AMap.Size(58, 70), // 图标尺寸
      //       image: require('@/assets/start.png'), // Icon的图像地址
      //       imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
      //     });
      //     let markerqi = new AMap.Marker({
      //       position: new AMap.LngLat(row.trackPath[i][0][0],row.trackPath[i][0][1]),
      //       offset: new AMap.Pixel(-10, -30),
      //       icon: qidian, // 添加 Icon 实例
      //       title: "自定义图标",
      //       zoom: 13,
      //     });
      //     that.map.add(markerqi);
      //     // 终点
      //     let zhongdian = new AMap.Icon({
      //       size: new AMap.Size(58, 70), // 图标尺寸
      //       image: require('@/assets/end.png'), // Icon的图像地址
      //       imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
      //     });
      //     let markerZhong = new AMap.Marker({
      //       position: new AMap.LngLat(row.trackPath[i][row.trackPath[i].length-1][0],row.trackPath[i][row.trackPath[i].length-1][1]),
      //       offset: new AMap.Pixel(-10, -30),
      //       icon: zhongdian, // 添加 Icon 实例
      //       title: "自定义图标",
      //       zoom: 13,
      //     });
      //     that.map.add(markerZhong);
      //   }
      // }
      // let sql = '1=1'
      // let coverage=''
      // let pol=''
      // if(row.networkPath) {
      //   // row.networkPath.forEach(item => {
      //       let link=''
      //       if(row.coverage){
      //         link=','
      //       }
      //       coverage=coverage+row.coverage
      //       let link2=''
      //       if(pol){
      //       link2=','
      //       }
      //       let path=row.networkPath
      //       if(path&&path.length>0){
      //           let newp=[]
      //           path.forEach(el => {
      //             newp.push(el.join(' '))
      //           });
      //           newp=newp.join(',')
      //           pol=pol+'(('+newp+'))'
      //       }
      //   // });
      //   if(coverage){
      //     // sql=sql+' and coverage in ('+ coverage+')'
      //     sql=sql+" and ((tableName = 'patrol_lines'  and coverage in ("+ coverage+")) or (tableName = 'tb_basic_pipe'  and coverage in ("+ coverage+")))"
      //   } 
        
      //   if(pol){    

      //       sql=sql+' and INTERSECTS(location,MULTIPOLYGON('+pol+'))'
      //   }
      // }
      
      // this.wms = new AMap.TileLayer.WMS({
      //   url: "/geoserver/wms",
      //   tileSize: 512,
      //   dataZooms: [0, 20],
      //   zooms: [0, 20],
      //   zIndex: 5,
      //   params: {
      //     LAYERS:	'linqing:view_all_line_q',//"linqing:view_all_line", //layer,
      //     VERSION: "1.1.1",
      //     TRANSPARENT: true,
      //     viewparams: `netid:${row.networkId}`,
      //  //   timestamp: Date.parse(new Date()),
      //     CQL_FILTER: sql,
      //   },
      // });
      //  that.map.add(this.wms);
      // let img = require('@/assets/legend/bj.png')
      // // let icon = new AMap.Icon({
      // //   size: new AMap.Size(58, 70), // 图标尺寸
      // //   image: img, // Icon的图像地址
      // //   imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
      // // });
      // if(row.equipment && row.equipment.length>0){
      //   for(let y=0;y<row.equipment.length;y++){
      //     console.log(row.equipment[y].locations)
      //         let marker = new AMap.Marker({
      //           position: new AMap.LngLat(row.equipment[y].locations[0],row.equipment[y].locations[1]),
      //           offset: new AMap.Pixel(-10, -10),
      //           icon: img, // 添加 Icon 实例
      //           title: "自定义图标",
      //           zoom: 13,
      //         });
      //         that.map.add(marker);
      //   }
      // }    
      // // 巡检点
      // let imgxunjian = require('@/assets/lineMap/已打卡.png')
      // let punchCard = require('@/assets/lineMap/未打卡.png')
      // if(row.punch && row.punch.length>0){
      //   for(let y=0;y<row.punch.length;y++){
      //     let icon = new AMap.Icon({
      //           size: new AMap.Size(58, 70), // 图标尺寸
      //           image: row.punch[y].isFlag == 1 ? imgxunjian : punchCard, // Icon的图像地址
      //           imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
      //         });
      //     // 已打卡
      //     if(row.punch[y].isFlag == 1){
      //       let marker = new AMap.Marker({
      //           position: new AMap.LngLat(row.punch[y].location.split(' ')[0],row.punch[y].location.split(' ')[1]),
      //           offset: new AMap.Pixel(-10, -10),
      //           icon: icon, // 添加 Icon 实例
      //           title: "自定义图标",
      //           zoom: 13,
      //         });
      //         that.map.add(marker);
      //     } else {
      //       // 未打卡
      //         let marker = new AMap.Marker({
      //           position: new AMap.LngLat(row.punch[y].location.split(' ')[0],row.punch[y].location.split(' ')[1]),
      //           offset: new AMap.Pixel(-10, -10),
      //           icon: icon, // 添加 Icon 实例
      //           title: "自定义图标",
      //           zoom: 13,
      //         });
      //         that.map.add(marker);
      //     }
      //   }
      // } 
      //       // let marker1 = new AMap.Marker({
      //       //   position: new AMap.LngLat(
      //       //     e.data.trackPath[e.data.trackPath.length - 1][0],
      //       //     e.data.trackPath[e.data.trackPath.length - 1][1]
      //       //   ),
      //       //   offset: new AMap.Pixel(-10, -10),
      //       //   icon: icon1, // 添加 Icon 实例
      //       //   title: "自定义图标",
      //       //   zoom: 13,
      //       // });
      // // 人员位置
      // if(row.userLocation && row.userLocation.length>0) {
      //   let personicon = new AMap.Icon({
      //           size: new AMap.Size(58, 70), // 图标尺寸
      //           image: require('@/assets/legend/xjr.png'), // Icon的图像地址
      //           imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
      //         });
      //     let personiconmarker = new AMap.Marker({
      //         position: new AMap.LngLat(row.userLocation[0],row.userLocation[1]),
      //         offset: new AMap.Pixel(-10, -10),
      //         icon: personicon, // 添加 Icon 实例
      //         title: "自定义图标",
      //         zoom: 13,
      //       });
      //       that.map.add(personiconmarker);
      // }
      // // 片区
      // if(row.networkPath && row.networkPath.length>0){
      //   // for(let z=0;z<row.networkPath.length;z++){
      //     let polygon1 = new AMap.Polygon({
      //       path: row.networkPath,
      //       strokeColor: "#A8DAEB",
      //       strokeWeight: 6,
      //       strokeOpacity: 0.2,
      //       fillOpacity: 0.4,
      //       fillColor: '#C3EEF6',
      //       zIndex: 50,
      //       bubble: true,

      //     })
      //     that.map.add(polygon1);
      //   // }
      // }
    })
  },
  openC(row) {
      let that = this;
      // 巡检轨迹
      this.datarow = row
      this.polyline=new AMap.OverlayGroup()
      if (row.trackPath && row.trackPath.length > 0) {
        for (let i = 0; i < row.trackPath.length; i++) {
          // 起点
          let qidian = new AMap.Icon({
            size: new AMap.Size(58, 70), // 图标尺寸
            image: require("@/assets/start.png"), // Icon的图像地址
            imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
          });
          this.markerqi = new AMap.Marker({
            position: new AMap.LngLat(
              row.trackPath[i][0][0][0],
              row.trackPath[i][0][0][1]
            ),
            offset: new AMap.Pixel(-10, -30),
            icon: qidian, // 添加 Icon 实例
            title: "自定义图标",
            zoom: 13,
          });
          that.map.add(this.markerqi);
          let arr=[]
          for (let j = 0; j < row.trackPath[i].length; j++) {
            if (j == row.trackPath[i].length - 1) {
              // 终点
              let lastLineLength = row.trackPath[i][j].length;

              let zhongdian = new AMap.Icon({
                size: new AMap.Size(58, 70), // 图标尺寸
                image: require("@/assets/end.png"), // Icon的图像地址
                imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
              });
              
              this.markerZhong = new AMap.Marker({
                position: new AMap.LngLat(
                  row.trackPath[i][j][lastLineLength - 1][0],
                  row.trackPath[i][j][lastLineLength - 1][1]
                ),
                offset: new AMap.Pixel(-10, -30),
                icon: zhongdian, // 添加 Icon 实例
                title: "自定义图标",
                zoom: 13,
              });
              that.map.add(this.markerZhong);
            }

            let polyline = new AMap.Polyline({
              path: row.trackPath[i][j],
              isOutline: true,
              outlineColor: "#ffeeff",
              borderWeight: 1,
              strokeColor: "#409EFF",
              strokeOpacity: 1,
              strokeWeight: 6,
              // 折线样式还支持 'dashed'
              strokeStyle: "solid",
              // strokeStyle是dashed时有效
              strokeDasharray: [10, 5],
              lineJoin: "round",
              lineCap: "round",
              zIndex: 2021,
              showDir:true,
            });
            arr.push(polyline)
            // that.map.add(this.polyline);
          }
          that.polyline.addOverlays(arr)
          that.map.add(this.polyline);
        }
      }
      that.drawLineSplic(row);

      let img = require("@/assets/legend/bj.png");
      if (row.equipment && row.equipment.length > 0) {
        let equipmentall = []
          that.equipmentes=new AMap.OverlayGroup()
        for (let y = 0; y < row.equipment.length; y++) {
          let equipmentes = new AMap.Marker({
            position: new AMap.LngLat(
              row.equipment[y].locations[0],
              row.equipment[y].locations[1]
            ),
            offset: new AMap.Pixel(-10, -10),
            icon: img, // 添加 Icon 实例
            title: "自定义图标",
            zoom: 13,
          });
          equipmentall.push(equipmentes)
          // that.map.add(this.equipmentes);
        }
        that.equipmentes.addOverlays(equipmentall)
            that.map.add(that.equipmentes);
      }
      // 打卡点
      let imgxunjian = require("@/assets/lineMap/已打卡.png");
      let punchCard = require("@/assets/lineMap/未打卡.png");
      if (row.punch && row.punch.length > 0) {
        let labelall = []
          that.labelsLayer=new AMap.OverlayGroup()
        for (let y = 0; y < row.punch.length; y++) {
          let icon = new AMap.Icon({
            size: new AMap.Size(58, 70), // 图标尺寸
            image: row.punch[y].isFlag == 1 ? imgxunjian : punchCard, // Icon的图像地址
            imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
          });
          // 已打卡
          if (row.punch[y].isFlag == 1) {
            let labelsLayer = new AMap.Marker({
              position: new AMap.LngLat(
                row.punch[y].location.split(" ")[0],
                row.punch[y].location.split(" ")[1]
              ),
              offset: new AMap.Pixel(-10, -10),
              icon: icon, // 添加 Icon 实例
              title: "自定义图标",
              zoom: 13,
            });
            labelall.push(labelsLayer)
            // that.map.add(this.labelsLayer);
          } else {
            // 未打卡
           let labelsLayer = new AMap.Marker({
              position: new AMap.LngLat(
                row.punch[y].location.split(" ")[0],
                row.punch[y].location.split(" ")[1]
              ),
              offset: new AMap.Pixel(-10, -10),
              icon: icon, // 添加 Icon 实例
              title: "自定义图标",
              zoom: 13,
            });
            labelall.push(labelsLayer)
            // that.map.add(this.labelsLayer);
          }
        }
        that.labelsLayer.addOverlays(labelall)
            that.map.add(that.labelsLayer);
      }
      // let marker1 = new AMap.Marker({
      //   position: new AMap.LngLat(
      //     e.data.trackPath[e.data.trackPath.length - 1][0],
      //     e.data.trackPath[e.data.trackPath.length - 1][1]
      //   ),
      //   offset: new AMap.Pixel(-10, -10),
      //   icon: icon1, // 添加 Icon 实例
      //   title: "自定义图标",
      //   zoom: 13,
      // });
      // 人员位置
      if (row.userLocation && row.userLocation.length > 0) {
        let personicon = new AMap.Icon({
          size: new AMap.Size(58, 70), // 图标尺寸
          image: require("@/assets/legend/xjr.png"), // Icon的图像地址
          imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
        });
        let personiconmarker = new AMap.Marker({
          position: new AMap.LngLat(row.userLocation[0], row.userLocation[1]),
          offset: new AMap.Pixel(-10, -10),
          icon: personicon, // 添加 Icon 实例
          title: "自定义图标",
          zoom: 13,
        });
        that.map.add(personiconmarker);
      }
      // 片区
      if (row.networkPath && row.networkPath.length > 0) {
        // for(let z=0;z<row.networkPath.length;z++){
        this.polygonNet = new AMap.Polygon({
          path: row.networkPath,
          strokeColor: "#A8DAEB",
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: "#C3EEF6",
          zIndex: 50,
          bubble: true,
        });
        that.map.add(this.polygonNet);
        // }
      }
  },
  drawLineSplic(row) {
      let that = this;
      this.wms = new AMap.OverlayGroup({
        collision: false,
        allowCollision: false,
      });
      that.map.add(this.wms);
      let coverage = row.coverage;
      let network = row.networkPath;
      let pos = [];
      network.forEach((element) => {
        pos.push(element[0] + " " + element[1]);
      });
      pos.push(pos[0]);
      let posStr = pos.join(",");
      let layersVue1 = coverage;
      let params = {
        SERVICE: "wfs",
        VERSION: "1.0.0",
        REQUEST: "GetFeature",
        typeName: "linqing:view_all_line_q",
        outputFormat: "application/json",
        viewparams: `netid:${row.networkId}`,
        CQL_FILTER:
          "coverage in (" +
          layersVue1 +
          ") and INTERSECTS(location,POLYGON((" +
          posStr +
          ")))",
      };
      if (!layersVue1) {
        params.CQL_FILTER = "1=2";
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      queryFeatrue(params).then((res) => {
        //调取管线
        loading.close();

        let newLine = [];
        let f = res.features;
        let line = [];
        f.forEach((item) => {
          let location = formatLocation(
            item.properties.location_str,
            "LineString"
          );
          for (let i = 1; i < location.length; i++) {
            let sub = [Number(location[i - 1][0]), Number(location[i - 1][1])];
            let sub1 = [Number(location[i][0]), Number(location[i][1])];
            line.push({ ...item.properties, line: [sub1, sub] });
          }
        });

        let py = network;
        // py.push(py[0])//首尾相接

        line.forEach((obj) => {
          //切割
          let item = obj.line;
          var line1 = turf.lineString(item);
          var line2 = turf.lineString(py);
          var intersects = turf.lineIntersect(line1, line2);
          if (intersects.features.length < 1) {
            //没相交是面内
            let pn = turf.polygon([py]);
            let isHave = turf.booleanContains(pn, line1);
            if (isHave) {
              newLine.push({ ...obj });
            }
          } else if (intersects.features.length > 0) {
            //一条线相交了两次及以上
            var line = turf.lineString(item);
            var splitter = turf.lineString(py);
            var splitline = turf.lineSplit(line, splitter);
            splitline.features.forEach((fea) => {
              let feaLine = fea.geometry.coordinates;
              let pn = turf.polygon([py]);
              let pl = turf.lineString(feaLine);
              let isHave = turf.booleanContains(pn, pl);
              if (isHave) {
                obj.line = feaLine;
                newLine.push({ ...obj });
              }
            });
          }
        });
        let group = [];
        for (let i = 0; i < newLine.length; i++) {
          let polyline = new AMap.Polyline({
            path: newLine[i].line,
            strokeColor: newLine[i].style_color,
            strokeOpacity: 1,
            strokeWeight: 2,
            strokeStyle: "solid",
            strokeDasharray: [10, 5],
            lineJoin: "round",
            lineCap: "round",
            zIndex: 2021,
          });
          //  this.map.add(polyline);
          group.push(polyline);
        }
        this.wms.addOverlays(group);
      });
    },
  }
}
</script>